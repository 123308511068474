.masthead {
	color: #f2f2f2;
	font-family: "PT Serif",serif;
	background-color: #222;
	padding: 0.5rem 1rem;
}
  
.masthead h1 {
	font-size: 2.8rem;
}

.masthead h2 {
	font-size: 1.5rem;
	color: #aaa;
}
  
.masthead a {
	color: inherit;
	text-decoration: none;
}
  
.masthead a:hover, .masthead a:focus {
	color: #f2f2f2;
}

.masthead .banner {
	margin-left: 1rem;
}
