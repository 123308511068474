.article-wrapper {
  min-height: 100vh;
  padding-top: 0rem;
}

article header[dir=ltr] {
	border-left: 4px solid #c7254e;
	padding-left: 6px;
}

article header[dir=rtl] {
	border-right: 4px solid #c7254e;
	padding-right: 6px;
}

.show .toc {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0px;
  left: 0;
  background-color: white;

}

body.show {
  //height: 100vh;
  overflow: hidden !important;
  //position: fixed;
}

.navbar-menu {
  float:none;
  margin:0 auto;
  display: block;
  text-align: center;
}

.navbar-menu > li {
  display: inline-block;
  float:none;
}

.sticky {
  position: sticky;
  top: 0px;
  z-index: 10;
}

.show .toc {
  margin-top: 0px;
  top: 0px;
  overflow-y: scroll;
	max-height: 95vh;
  min-width: 100vw;
  padding-bottom: 5rem;
  //margin-bottom: 32px;
}

.btn-menu {
  //margin: 0;
  padding: 8px;
  border-right: 1px solid #d5dbdb;
  border-bottom: 0px;
  border-top: 0px;
  border-left: 0px;
  //left: 0px;
  //position: absolute;
}

.btn-menu:focus {
  box-shadow: none;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  word-wrap: break-word;
  box-sizing: border-box;
  min-width: 200px;
  //max-width: 760px;
  //margin-right: 24px;
  //padding: 45px;
  //padding-top: 0px;
  //padding-bottom: 45px;
  //padding-left: 24px;
  //padding-right: 24px;

  a {
    color: #0366d6;
    background-color: transparent;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;

    &:active,
    &:hover {
      outline-width: 0;
    }
    &:hover {
      text-decoration: underline;
    }
    &:not([href]) {
      color: inherit;
      text-decoration: none;
    }
  }

  strong {
    font-weight: 600;
  }

  // ------------------------------------------------------------------------------
  //                                    Headings                                   
  // ------------------------------------------------------------------------------
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #333;
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaecef;
    font-family: "PT Serif", serif;
  }

  h2 {
    padding-bottom: 0.3em;
    font-size: 1.5em;
    border-bottom: 1px solid #eaecef;
    font-family: "PT Serif", serif;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.875em;
  }

  h6 {
    font-size: 0.85em;
    color: #6a737d;
  }

  // ------------------------------------------------------------------------------

  img {
    border-style: none;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  code,
  kbd,
  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  hr {
    box-sizing: content-box;
    height: 0.25em;
    margin: 24px 0;
    padding: 0;
    overflow: hidden;
    background-color: #e1e4e8;
    border: 0;

    &::before {
    display: table;
    content: "";
    }

    &::after {
    display: table;
    clear: both;
    content: "";
    }
  }

  input {
    margin: 0;
    overflow: visible;
    font: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  [type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }




  p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  blockquote {
    margin: 0;
  }

  // ------------------------------------------------------------------------------
  //                                     Lists                                     
  // ------------------------------------------------------------------------------
  ul,
  ol {
    padding-left: 2em;
    margin-top: 0;
    margin-bottom: 0;

    ol {
      list-style-type: lower-roman;
    }

    ul,
    ol {
      margin-top: 0;
      margin-bottom: 0;
    }

    ul ol,
    ol ol {
      list-style-type: lower-alpha;
    }
 }

  li>p {
    margin-top: 16px;
  }

  li+li {
    margin-top: 0.25em;
  }

  // ------------------------------------------------------------------------------
  //                               Description Lists                               
  // ------------------------------------------------------------------------------

  dd {
    margin-left: 0;
  }

  dl {
    padding: 0;

    & dt {
      padding: 0;
      margin-top: 16px;
      font-size: 1em;
      font-style: italic;
      font-weight: 600;
    }

    & dd {
      padding: 0 16px;
      margin-bottom: 16px;
    }
  }


  // ------------------------------------------------------------------------------

  code {
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    font-size: 12px;
  }

  pre {
    margin-top: 0;
    margin-bottom: 0;
    font: 12px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  }


  // ------------------------------------------------------------------------------

  p,
  blockquote,
  ul,
  ol,
  dl,
  table,
  pre {
    margin-top: 0;
    margin-bottom: 16px;
  }



  blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;

    &>:first-child {
      margin-top: 0;
    }

    &>:last-child {
      margin-bottom: 0;
    }
  }


  kbd {
    display: inline-block;
    padding: 3px 5px;
    font-size: 11px;
    line-height: 10px;
    color: #444d56;
    vertical-align: middle;
    background-color: #fafbfc;
    border: solid 1px #c6cbd1;
    border-bottom-color: #959da5;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #959da5;
  }







  // ------------------------------------------------------------------------------
  //                                     Tables                                    
  // ------------------------------------------------------------------------------
  table {
    display: block;
    width: 100%;
    overflow: auto;
    border-spacing: 0;
    border-collapse: collapse;

    & th {
      font-weight: 600;
    }

    & th,
    & td {
      padding: 6px 13px;
      border: 1px solid #dfe2e5;
    }

    & tr {
      background-color: #fff;
      border-top: 1px solid #c6cbd1;

      &:nth-child(2n) {
        background-color: #f6f8fa;
      }
    }


  }


  // ------------------------------------------------------------------------------

  img {
    max-width: 100%;
    box-sizing: content-box;
    background-color: #fff;
  }


  code {
    padding: 0;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27,31,35,0.05);
    border-radius: 3px;
  }

  code::before,
  code::after {
    letter-spacing: -0.2em;
    content: "\00a0";
  }

  pre {
    word-wrap: normal;
  }

  pre>code {
    padding: 0;
    margin: 0;
    font-size: 100%;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
  }

  .highlight {
    margin-bottom: 16px;
  }

  .highlight pre {
    margin-bottom: 0;
    word-break: normal;
  }

  .highlight pre,
  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
  }

  pre code {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
  }

  pre code::before,
  pre code::after {
    content: normal;
  }

  .full-commit .btn-outline:not(:disabled):hover {
    color: #005cc5;
    border-color: #005cc5;
  }

  kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 11px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    line-height: 10px;
    color: #444d56;
    vertical-align: middle;
    background-color: #fcfcfc;
    border: solid 1px #c6cbd1;
    border-bottom-color: #959da5;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #959da5;
  }

  // ------------------------------------------------------------------------------
  //                                   Task-Lists                                  
  // ------------------------------------------------------------------------------
  :checked+.radio-label {
    position: relative;
    z-index: 1;
    border-color: #0366d6;
  }

  .task-list-item {
    list-style-type: none;
    
    &+.task-list-item {
      margin-top: 3px;
    }

    & input {
      margin: 0 0.2em 0.25em -1.6em;
      vertical-align: middle;
    }
  }

  // ------------------------------------------------------------------------------
  //                                 .markdown-body                                
  // ------------------------------------------------------------------------------

  // .markdown-body::before
  &::before {
    display: table;
    content: "";
  }

  // .markdown-body::after
  &::after {
    display: table;
    clear: both;
    content: "";
  }

  // .markdown-body >*:first-child
  &>*:first-child {
    margin-top: 0 !important;
  }

  // .markdown-body >*:child
  &>*:last-child {
    margin-bottom: 0 !important;
  }
}

.padding-left-0 {
  margin-left: 0px;
}

.test3 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  //border-right: 1px solid #d5dbdb;
  //border-left: 1px solid #d5dbdb;
  //border-bottom: 1px solid #d5dbdb;
}

.test4 {
  border-left: 1px solid #d5dbdb;
}

#nav-topNav {
  border-bottom: 1px solid #d5dbdb;
  padding: 0rem;
  margin-bottom: 0rem;
}

#nav-topNav a {
  transition: color 0.3s ease 0s, text-decoration 0.3s ease 0s, background 0.3s ease 0s;
  padding-right: 1rem;
}

#nav-topNav a:hover,
#nav-topNav li > a:focus {
  color: #c7254e;
}

.btn-nav {
  //margin: 0;
  padding: 6px;
  border-left: 1px solid #d5dbdb;
  border-bottom: 0px;
  border-top: 0px;
  border-right: 0px;
  //right: 0px;
  //position: static;
}

.btn-nav:focus {
  box-shadow: none;
}

.hr-padding {
  margin: 0px;
  color: #d5dbdb;
  opacity: 1;
}

a:hover,
a:focus {
    color: #c7254e;
    border-color: #ddd;
    text-decoration: none;
}

a {
  //font-family: "PT Serif", serif;
  text-decoration: none;
  color: #212529
}

.tags a {
  color: #aaa;
}

h1.title,
p.subtitle {
	margin-bottom: 0 !important;
}

.meta {
	color: gray;
}

* {
  scroll-margin-top: 64px;
}

.blog-content {
  padding-left: 1rem;
  padding-right: 1rem;
  //margin-left: 2rem;
}
