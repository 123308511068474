.author-img {
	width: 128px;
	display: block;
	margin-top:40px;
	margin-bottom:1rem;
	margin-right:auto;
	margin-left:auto;
	border-radius: 50%;
}

.author {
	text-align: center;
    padding-top: 1rem;
	font-size: 1rem;
	//padding-top: 12px;
	//padding-left: 1rem;
	padding-bottom: 45px;
	//padding-right: 1rem;
}



.author a {
    text-decoration: none;
}

.author a:hover,
.author a:focus {
    color: #333;
    border-color: #ddd;
    text-decoration: none;
}

.author a {
    color: #333;
}