.footer {
	text-align: center;
    background-color: #222;
    color: #aaa;
    padding: 1.2rem;
    margin-top: auto;
}

.footer p {
    margin:0;
    line-height: 1rem;
}

.footer a, #footer a:hover {
    color: inherit;
}
